@use "./variables" as *;

@mixin card($alignment){
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $border-redius-card;
    min-height: 10rem;
    height: fit-content;
    width: 20rem;
    max-width: 90%;
    padding-bottom: 1rem;
    gap: 0.8rem;
    background-color: $background-color;
    box-shadow: $drop-shadow;
    align-self: $alignment;
    
    
}