@use "./../abstracts/variables" as *;
@use "./../abstracts/mixins" as *;




.get-started-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.25rem;
    height: fit-content;
    width: 45%;
}

.get-started-button {
    align-self: center;
    text-align: center;
    font-family: $text-font-family;
    height: auto;
    width: max-content;
    max-width: 80%;
    padding: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    color: $background-color;
    background-color: $highlight-color;
    border-radius: $border-radius;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    cursor: pointer;
}


.gForm {
    a {
        font-family: $text-font-family;
        color: $highlight-color;
        text-decoration: none;
        font-weight: bold;
    }

}

.Sign-in-button {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 1rem;
    align-self: center;
    text-align: center;
    font-family: $text-font-family;
    height: auto;
    width: max-content;
    max-width: 80%;
    padding: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    color: $background-color;
    background-color: $highlight-color;
    border-radius: $border-radius;
    font-weight: bold;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: 1.5rem;
    cursor: pointer;

    p {
        margin-top: 0;
        color: map-get($paragraph-color, "dark-mode");
        ;
    }

    @media(max-width: 700px) {
        margin-left: auto;
    }
}