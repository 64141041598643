@use "./../abstracts/variables" as *;

.homeWrapper{
    display: flex;
    flex-direction: column;
}

.Hero{
    position: relative;
    margin-top: 5%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 50%;
    padding-left: 10%;
    @media(max-width: 900px){
        padding-right: 40%;
    }
    @media(max-width: 700px){
        padding-right: 10%;
    }
}

.Background{
    background-image: url('../../resources/Background-Home(1).svg');
    position: absolute;
    top: 0px;
    z-index: -1000;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    @media(max-width:700px){
        display: none;
    }
}