@use "./../abstracts/variables" as *;
@use "./../abstracts/mixins" as *;

.footprint-grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 20rem);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    place-items: center;
    padding: 0.1rem;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 700px){
        grid-template-columns: repeat(auto-fit, 15rem);
    }

    @media(max-width: 220px){
        grid-template-columns: repeat(auto-fit, 10rem);
    }
    
}