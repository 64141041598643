@use "./../abstracts/variables" as *;


a {
    text-decoration: none;
}

h1 {
    font-family: $logo-font-family;
    
    letter-spacing: 0.5rem ;
    font-variant: normal;
    font-size: 2rem;
    letter-spacing: 1.5px;
    color: $highlight-color;
    @media (max-width: 700px) {
        text-align: center;
    }
}

h2 {
    font-family: $text-font-family;
    font-style: normal;
    font-weight:bold;
    font-size: 3rem;
    color: $highlight-color;
    @media (max-width: 700px) {
        text-align: center;
    }

}

h3 {
    font-family: $text-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;

    color: $heading-color;
    @media (max-width: 700px) {
        text-align: center;
    }
}

h6{
    font-family: $text-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 0rem;
    color: $heading-color;
    @media (max-width: 700px) {
        text-align: center;
    }
}

p {
    margin-top: 1rem;
    font-family: $text-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 236.19%;
    /* or 52px */
    letter-spacing: 0.1rem;
    color: map-get($paragraph-color, "light-mode" );
    @media (max-width: 700px) {
        text-align: center;
    }
}

#logo h1 {
    font-family: $logo-font-family;
    font-style: italic;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 3rem;
    
    /* identical to box height */

    text-align: center;

    color: $highlight-color;


}

h6{
    line-height: 2rem;
}
