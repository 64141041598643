// Initial setup
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-family: Arial, Helvetica, Sansita-One, sans-serif;
}

// Variables
:root {
    --colour-light-blue: #8798FF;
    --colour-lightest-blue: #EDEFFB;
    --colour-med-blue: #4c68ef;
    --colour-dark-blue: #3D3FA4;
    --colour-white: #FFFFFF;
    --colour-light-grey: #EDEDED;
    --colour-grey: #83838B;
    --colour-black: #000000;
    --br-sm-2: 14px;
    --box-shadow-1: 0 3px 15px rgba(0,0,0,.3)
}

.nav-bar {
  background: white;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  width: 100%;
  height: 75px;
  position: fixed;
  top: 0%;
  left: 0;
  box-shadow: 0px 0.5px;
  z-index: 999;
}

.nav-item #footprint-header{
  color:  var(--colour-med-blue);
}

// Vendee 
h1 {
  width: 100%;
  text-align: left;
  color: var(--colour-med-blue);
  font-family: 'Sansita', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  // text-align: center;
}

.title{
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5%;
  margin-top: 1%;
} 

.subtitle {
  font-size: 18px;
  margin-left: 5%;
  color: var(--colour-grey);
  margin-top: 10px;
}

@media (max-width: 650px) {
  .title{
    font-size: 32px;
    margin-left: 0;
    display: flex;
    text-align: center;
    
    justify-content: center;
    align-items: center;
  } 
  
  .subtitle {
    // font-size: 9px;
    width: 100%;
    margin-left: 0;
    display: flex;
    text-align: center;
    
    justify-content: center;
    align-items: center;
  }
}

.logo{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

// Logout
form {
  // display: none;
  width: 20%;
  text-align: center;
}

input {
  background: none;
  border: none;
  font-size: 1.1rem;
  font-weight: 200;
  color: var(--colour-black);
  transition: .4s;
  &:hover {
    color: var(--colour-med-blue);
    cursor: pointer;
  }
}

// Sender List
ul {
  display: relative;
  list-style: none;
  margin-top: 75px;
  z-index: 100;
  overflow: auto;
  li{
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2%;
    width: 100%;
    padding: 0.5rem;
    border: 0.5px solid var(--colour-light-grey);
    div {
      display: inline-block;       
    }
    .sender-text {
      width: 80%;
      padding-left: 2%;
    }
    .sender-count {
      width: 20%;
    }
    .sender-description{
        width: 60%;
        padding-left: 2%;
        font-weight: bold;
    }
    .count-description{
        width: 40%;
        text-align: right;
        padding-right: 2%;
        font-weight: bold;
        color: var(--colour-dark-blue);
    }
    .sender-name{
        text-align: left;
        font-size: 2rem;
        font-weight: 500;
        color: var(--colour-black);
    }
    .sender-email{
        text-align: left;
        color: var(--colour-grey);
    }
    .sender-figure{
      text-align: center;
      font-size: 2rem;
      color: var(--colour-dark-blue);
    }
  }
}

.scroll-wrapper{
  overflow-y: auto;
  overflow-x:hidden;
}

ul .inbox{
  display: none;
  margin-top: 75px;
  z-index: 100;
  li{
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2%;
    width: 100%;
    padding: 0.5rem;
    border: 0.5px solid var(--colour-light-grey);
    div {
      display: inline-block;       
    }
    .sender-name{
        text-align: left;
        font-size: 2rem;
        font-weight: 500;
        color: var(--colour-black);
    }
    .sender-subject{
        text-align: left;
        color: var(--colour-grey);
    }
  }
}
  

//
// Loading Screen that I found online
//

*,
*:after,
*:before {
  box-sizing: border-box;
}
:root {
  --hue: 230;
  --radius: 5.5;
  --size: 4;
}
body {
  min-height: 100vh;
  background: var(--colour-white);//hsl(var(--hue), 20%, 70%);
  //overflow: hidden;
  margin: 0;
  padding: 0;
}
[type='checkbox'] {
  position: absolute;
  left: 100%;
  opacity: 0;
  height: 0;
  width: 0;
}
.scene {
  perspective: 800px;
  transform-style: preserve-3d;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: -1000;
}
.plane {
  height: 5vmin;
  width: 5vmin;
  transform-style: preserve-3d;
  transform: rotateX(-40deg) rotateY(-45deg) rotateX(90deg);
}
.cube,
.shadow {
  --width: var(--size);
  --height: var(--size);
  --depth: var(--size);
  height: calc(var(--depth) * 1vmin);
  width: calc(var(--width) * 1vmin);
  transform-style: preserve-3d;
  position: absolute;
  font-size: 1rem;
  animation: jump 0.8s calc(var(--d) * 1s) infinite;
  transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 0);
}
.cube > div:nth-of-type(1),
.shadow > div:nth-of-type(1) {
  background: hsl(var(--hue), 60%, 50%);
  height: calc(var(--height) * 1vmin);
  width: 100%;
  transform-origin: 50% 50%;
  transform: rotateX(-90deg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateX(-90deg) translate3d(0, 0, calc((var(--depth) / 2) * 1vmin));
}
.cube > div:nth-of-type(2),
.shadow > div:nth-of-type(2) {
  background: hsl(var(--hue), 60%, 60%);
  height: calc(var(--height) * 1vmin);
  width: 100%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%) rotateX(-90deg) rotateY(180deg) translate3d(0, 0, calc((var(--depth) / 2) * 1vmin));
  position: absolute;
  top: 50%;
  left: 50%;
}
.cube > div:nth-of-type(3),
.shadow > div:nth-of-type(3) {
  background: hsl(var(--hue), 60%, 60%);
  height: calc(var(--height) * 1vmin);
  width: calc(var(--depth) * 1vmin);
  transform: translate(-50%, -50%) rotateX(-90deg) rotateY(90deg) translate3d(0, 0, calc((var(--width) / 2) * 1vmin));
  position: absolute;
  top: 50%;
  left: 50%;
}
.cube > div:nth-of-type(4),
.shadow > div:nth-of-type(4) {
  background: hsl(var(--hue), 60%, 55%);
  height: calc(var(--height) * 1vmin);
  width: calc(var(--depth) * 1vmin);
  transform: translate(-50%, -50%) rotateX(-90deg) rotateY(-90deg) translate3d(0, 0, calc((var(--width) / 2) * 1vmin));
  position: absolute;
  top: 50%;
  left: 50%;
}
.cube > div:nth-of-type(5),
.shadow > div:nth-of-type(5) {
  background: hsl(var(--hue), 60%, 45%);
  height: calc(var(--depth) * 1vmin);
  width: calc(var(--width) * 1vmin);
  transform: translate(-50%, -50%) translate3d(0, 0, calc((var(--height) / 2) * 1vmin));
  position: absolute;
  top: 50%;
  left: 50%;
}
.cube > div:nth-of-type(6),
.shadow > div:nth-of-type(6) {
  background: hsl(var(--hue), 60%, 65%);
  height: calc(var(--depth) * 1vmin);
  width: calc(var(--width) * 1vmin);
  transform: translate(-50%, -50%) translate3d(0, 0, calc((var(--height) / 2) * -1vmin)) rotateX(180deg);
  position: absolute;
  top: 50%;
  left: 50%;
}
.shadow {
  animation: none;
  background: radial-gradient(#000, transparent);
  transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(1);
  animation: shadow 0.8s calc(var(--d) * 1s) infinite;
}
.cube--0,
.shadow--0 {
  --x: 1;
  --y: 0;
  --d: -0.7;
}
.cube--1,
.shadow--1 {
  --x: 1;
  --y: -1;
  --d: -0.6;
}
.cube--2,
.shadow--2 {
  --x: 0;
  --y: -1;
  --d: -0.5;
}
.cube--3,
.shadow--3 {
  --x: -1;
  --y: -1;
  --d: -0.4;
}
.cube--4,
.shadow--4 {
  --x: -1;
  --y: 0;
  --d: -0.3;
}
.cube--5,
.shadow--5 {
  --x: -1;
  --y: 1;
  --d: -0.2;
}
.cube--6,
.shadow--6 {
  --x: 0;
  --y: 1;
  --d: -0.1;
}
.cube--7,
.shadow--7 {
  --x: 1;
  --y: 1;
  --d: 0;
}
@-moz-keyframes jump {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 0);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 3.5vmin);
  }
}
@-webkit-keyframes jump {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 0);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 3.5vmin);
  }
}
@-o-keyframes jump {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 0);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 3.5vmin);
  }
}
@keyframes jump {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 0);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), 3.5vmin);
  }
}
@-moz-keyframes shadow {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(1);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(0.85);
    opacity: 0.2;
  }
}
@-webkit-keyframes shadow {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(1);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(0.85);
    opacity: 0.2;
  }
}
@-o-keyframes shadow {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(1);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(0.85);
    opacity: 0.2;
  }
}
@keyframes shadow {
  0%, 30%, 100% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(1);
  }
  15% {
    transform: translate3d(calc((var(--x) * var(--radius)) * 1vmin), calc((var(--y) * var(--radius)) * -1vmin), calc(var(--size) * -0.5vmin)) scale(0.85);
    opacity: 0.2;
  }
}

.nav-item{
  cursor: pointer;
}
