@use "./../abstracts/variables" as *;
@use "./../abstracts/mixins" as *;

.card {
    @include card($align-start);
    border-top: .75rem;
}

.card-center {
    @include card($align-end);

}

.card-title {
    text-align: center;
    font-family: $text-font-family;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2rem;
    gap: .3rem;
}

.card-content {
    font-family: $text-font-family;
    text-align: center;
    color: map-get($paragraph-color, "light-mode");
    width: fit-content;
    line-height: 150%;
    letter-spacing: 0.1rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.card-image {

    display: flex;
    border-radius: 100%;
    border: 2px solid #EDEFFB;
    width: 40%;
    height: 40%;
    align-content: center;
    justify-content: center;
    margin-top: 1rem;
    overflow: hidden;

    img {
        display: inline;
        height: 40%;
        margin: 0 auto;
        width: 100%;
    }
}

.social-card {
    width: 16rem;
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: 10px;
    justify-content: flex-start;
    gap: 5rem;
    margin-bottom: .75rem;
    border-radius: $border-radius;
    box-shadow: $drop-shadow;
}

.social-card-icon {
    width: 2rem;
    height: 2rem;
    margin-left: .3rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.social-card-text {
    font-family: $text-font-family;
    color: $heading-color;
}