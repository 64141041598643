@use "./../abstracts/variables" as *;

/*Nav stuff here*/
nav {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 4.5rem;
    margin-left: 3rem;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    width: 100vw;

    @media(max-width: 900) {

        z-index: 1000;
        margin-bottom: 0;
    }

    @media(max-width: 700px) {
        justify-content: space-between;
        margin-right: 10%;
    }


}

.logo {
    margin-right: 2.5rem;
    margin-left: 0%;
}

nav.app {

    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    padding-left: 5%;
    padding-right: 10%;
    flex-wrap: wrap;



    .logo {
        margin-right: 0rem;
        margin-left: 0%;
    }
}

@media(max-width: 380) {
    nav.app {
        padding: 0%;
        padding-right: none;
        gap: .3rem;
    }
}

.nav-container {
    max-height: 4.75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    z-index: 1000;
}





.nav-items {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    border-left: solid 1px $heading-color;
    float: left;
    margin-top: 0;
    z-index: 1000;

    li {
        font-family: $text-font-family;
        font-size: 1.25rem;
        font-weight: bold;
        color: #83838B;
        display: inline-block;
        margin-left: 2.5rem;
        width: max-content;
        overflow: none;
        border: none;
        background-color: rgba($color: #ffffff, $alpha: 0);
    }

    a {
        color: map-get($paragraph-color, "light-mode");
        text-decoration: none;
    }




}



.nav-items li.current-nav-item a {
    font-family: $text-font-family;
    font-size: 1.25rem;
    font-weight: bold;
    color: $heading-color;
    text-decoration: none;

    z-index: 1000;
}



.nav-items a {
    color: map-get($paragraph-color, "light-mode");
    ;
    text-decoration: none;

    z-index: 1000;
}

@media(max-width:850px) {
    li.Business a {
        color: map-get($paragraph-color, "dark-mode");
    }

}

@media(max-width:1150px) {
    li.About a {
        color: map-get($paragraph-color, "dark-mode");
    }

}

@media(max-width:700px) {
    li.About a {
        color: map-get($paragraph-color, "light-mode");
    }

    li.Business a {
        color: map-get($paragraph-color, "light-mode");
    }

}

.nav-items li a:hover {
    color: $highlight-color;

    z-index: 1000;
}


.menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1000;
}

#menu-toggle {
    display: none;
}


.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: $highlight-color;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;

    z-index: 1000;
}

.menu-button::before {
    content: '';
    margin-top: -8px;

    z-index: 1000;
}

.menu-button::after {
    content: '';
    margin-top: 8px;

    z-index: 1000;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);

    z-index: 1000;
}

#menu-toggle:checked+.menu-button-container .menu-button {
    background: $background-color;

    z-index: 1000;
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);

    z-index: 1000;
}

@media (max-width: 700px) {
    .nav-container {
        justify-content: space-evenly;
        margin-bottom: 1rem;

        z-index: 1000;
    }

    .menu-button-container {
        display: flex;
        justify-self: flex-end;

        z-index: 1000;
    }

    .nav-items {
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-left: none;

        z-index: 1000;
    }

    #menu-toggle~.nav-items li {
        display: none;
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);

        z-index: 1000;
    }

    #menu-toggle:checked~.nav-items li {
        display: flex;
        height: 2.5em;
        padding: 0.5em;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);

        z-index: 1000;
    }

    .nav-items>li {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0.5em 0;
        width: 100%;
        color: white;
        background-color: $background-color;

        box-shadow: $drop-shadow;

        z-index: 1000;
    }

    .nav-items>li:not(:last-child) {
        box-shadow: $drop-shadow;
    }
}