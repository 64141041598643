@use "./../abstracts/variables" as *;
@use "./../abstracts/mixins" as *;

/* Popup style */
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_inner {

    
    margin-left: auto;
    margin-right: auto;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $border-redius-card;
    min-height: 10rem;
    height: fit-content;
    width: 80%;
    max-width: 30rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1rem;
    gap: 0.8rem;
    background-color: $background-color;
    box-shadow: $drop-shadow;

    h1 {
        text-align: center;
        margin-top: 1rem;
    }
    p{
        width: 80%;
        margin-bottom: 2rem;
    }
    h4{
        margin-bottom: 2rem;
    }

    
}

.popup-img{
    display: flex;
    border-radius: 50%;
    border: 2px solid #EDEFFB;
    width: 40%;
    height: 40%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1.5rem;
    align-content: center;
    justify-content: center;
    margin-top: 1rem;
    img {
        display: inline;
        height: 80%;
        margin: 0 auto;
        width: 80%;
    }
}

.popup-name{
    text-align: center;
    font-family: $text-font-family;
    font-size: 3rem;
    font-weight: bold;
    line-height: 2rem;
    margin-bottom: 1rem;
    gap: .3rem;
}

.popup-text{
    font-family: $text-font-family;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: map-get($paragraph-color, "light-mode" );
    width: fit-content;
    line-height: 150%;
    letter-spacing: 0.1rem;
    margin-bottom: 1.5rem;
    margin-top: .5rem;
    margin-left: auto;
    margin-right: auto;
}

.popup-domain{
    text-align: center;
    font-family: $text-font-family;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
    gap: .3rem;
}

.double-buttons{
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
}

.closeButton{
    position: relative;
    height: 3rem;
    width: 3rem;
    top: -1rem;
    right: 4rem;
    left: 98%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 3rem;
    vertical-align: top;
    background-color: $background-color;
    border: 3px solid $alt-highlight-color;
    cursor: pointer;

}