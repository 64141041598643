@import url(https://fonts.googleapis.com/css2?family=Shrikhand&display=swap);
$highlight-color: #4C68EF;
$alt-highlight-color:#8798FF;
$gradient-top: linear-gradient(#3D3FA4, #4C68EF);
$gradient-mid:linear-gradient(180deg, #4C68EF 1.56%, #3D3FA4 35.76%, #3D3FA4 68.06%, #4D69EF 85%);
$heading-color: black;
$paragraph-color: ("light-mode":#83838B,"dark-mode":#EDEDED);
$background-color:#ffffff;
$logo-font-family:"Shrikhand", cursive;
$text-font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$border-radius: 0.8rem;
$border-redius-card: 1.8rem; 
$border-radius-form: 0.3rem;
$align-start: flex-start;
$align-end:flex-end;
$drop-shadow:rgba(0, 0, 0, 0.35) 0rem .35rem 1rem;
